.provider-connected-container {
  border: 1px solid #999;
  border-radius: 4px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;

  .account-info {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f0f0f0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #999;

    .account-logo {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .account-email {
      font-size: 14px;
      color: #333;
    }
  }

  .provider-connected-file-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 15px;
    position: relative;

    .file-details {
      display: flex;
      align-items: center;

      .file-icon {
        font-size: 20px;
        color: #d24726;
        margin-right: 10px;
      }

      .file-text {
        display: flex;
        flex-direction: column;

        .file-title {
          font-size: 14px;
          color: #333;
          font-weight: bold;
        }

        .file-item-count {
          font-size: 12px;
          color: #666;
          margin-top: 5px;
          font-style: italic;
        }
      }
    }

    .file-options-icon {
      font-size: 14px;
      color: #333;
      cursor: pointer;
      border-radius: 4px;
      position: relative;

      &.clicked {
        background-color: #e0e0e0;
      }
    }
  }
}
